import React from "react"
import { graphql, PageProps } from "gatsby"
import { Kind } from "../../../common/kind"
import { SeriesListPageQuery } from "../../../types/gatsby-graphql.generated"
import { compareByDate } from "../../base/date"
import { ListPageHeadLayout, ListPageLayout } from "../../layouts/list"
import { ListItemSeries } from "../../views/content"

export function Head() {
    return <ListPageHeadLayout isHome={false} kind={Kind.Series} />
}

export default function SeriesListPage({
    data,
}: PageProps<SeriesListPageQuery>) {
    return (
        <ListPageLayout
            isHome={false}
            kind={Kind.Series}
            items={data.allMarkdownRemark.nodes
                .sort(compareByDate)
                .map(node => (
                    <ListItemSeries key={node.path} {...node} />
                ))}
        />
    )
}

export const pageQuery = graphql`
    query SeriesListPage {
        allMarkdownRemark(
            filter: {
                frontmatter: { kind: { eq: "series" } }
                published: { eq: true }
            }
        ) {
            nodes {
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                topics {
                    path
                    title
                }
                items {
                    path
                    title
                    updatedAt
                    publishedAt
                    topics {
                        path
                        title
                    }
                }
            }
        }
    }
`
